import React, { useEffect } from "react";
import { Link } from "react-router-dom";
const Home = () => {
  useEffect(() => {
    const timeout = setTimeout(() => {
      window.location.replace('https://www.fountain.com/');
    }, 0);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <div className="dhiwise-navigation">
      <h1></h1>
      <p className="headline">
      
      </p>
    </div>
  );
};
export default Home;